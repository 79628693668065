import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href={`${window.location.protocol}//${window.location.host}/applinkpoc`}
          rel="noopener noreferrer"
        >
          {window.location.protocol}//{window.location.host}/applinkpoc
        </a>
        <a
          className="App-link"
          href={`${window.location.protocol}//${window.location.host}/applinkverify`}
          rel="noopener noreferrer"
        >
          {window.location.protocol}//{window.location.host}/applinkverify
        </a>
        <a
          className="App-link"
          href={`${window.location.protocol}//${window.location.host}/fashion/?lp=https://www.google.com`}
          rel="noopener noreferrer"
        >
          {window.location.protocol}//{window.location.host}/fashion/?lp=https://www.google.com
        </a>
        <a className="App-Link" href="https://stg-rd-dp.rmp.rakuten.co.jp/fashion/?lp=https://brandavenue.rakuten.co.jp/item/FD7359/" rel="noopener noreferrer">
           https://stg-rd-dp.rmp.rakuten.co.jp/fashion/?lp=https://brandavenue.rakuten.co.jp/item/FD7359/
        </a>
     </header>
    </div>
  );
}

export default App;
